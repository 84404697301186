
<template>
    <div>
        <div style="border-bottom: 3px solid #475c78;">
            <h4 class="temp_head mt-20 mb-10"><span style="color: #223957;"> Templates</span></h4>
            <!-- <h4 class="temp_head mt-20 mb-10"><span style="color: #223957;">Selected Templates</span></h4> -->
        </div>
        <div v-if="edititem == null">
            <div class="col-md-12 row mt-20 mb-20">
                <div class="col-md-4" v-for="item in list" :key="item.id">
                    <single-whats-app-template :item="item" :key="item.id"></single-whats-app-template>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="col-md-12">
                <selected-whats-app-template></selected-whats-app-template>
            </div>
            <!-- <whats-app-user></whats-app-user> -->
            <div class="col-md-12 mb-20">
                <div class="panel panel-cascade">
                    <div class="panel-body ">
                        <div class="col-md-12">
                            <div>
                                <div class="mb-30 mt-20">
                                    <div class="col-md-12 ">
                                        <div class="">
                                            <div class="col-md-12  mb-40">
                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <h5 class=" control-label">
                                                            <span class="selected-temp">Customers</span>
                                                        </h5>
                                                    </div>
                                                    <div class="col-md-4 row text-end">
                                                        <div class="col-md-7">
                                                            <span
                                                                style="padding: 10px 5px 5px 5px;border: 1px solid gray;border-radius: 3px;">
                                                                <i class='bx bx-user-circle' style="font-size: 20px;"></i>
                                                                <span style="font-size: 18px;margin-left: 5px;"
                                                                    v-if="this.userlist != ''">{{ this.totaluser }}</span>
                                                                <span style="font-size: 18px;margin-left: 5px;"
                                                                    v-if="this.userleadlist != ''">{{ this.totaluserlead
                                                                    }}</span>
                                                            </span>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <div v-if="this.userids">
                                                                <input hidden type="checkbox" id="selectall"
                                                                    v-model="selectAll" @click="toggleUserIdsCheckboxes()">
                                                                <label for="selectall" class="select_all" v-if="selectAll">
                                                                    Select All</label>
                                                                <label for="selectall" class="select_all" v-else> Deselect
                                                                    All</label>
                                                            </div>
                                                            <div v-if="this.usermobiles">
                                                                <input hidden type="checkbox" id="selectall"
                                                                    v-model="selectAll"
                                                                    @click="toggleUserMobilesCheckboxes()">
                                                                <label for="selectall" class="select_all" v-if="selectAll">
                                                                    Select All</label>
                                                                <label for="selectall" class="select_all" v-else>Deselect
                                                                    All</label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 row">
                                    <div class="col-md-3" v-for="(item, index) in userids" :key="index">
                                        <div class="customer_data">
                                            <input type="checkbox" :id="item.id" :value="item.id"
                                                v-model="selecteduser[index]" @click="selected($event)">
                                            <label :for="item.id" class="ml5px" style="font-size: 15px;">{{ item.name
                                            }} </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-12 row" v-if="this.userids?.length>0">
                                    <div class="col-md-3" v-for="item in userids" :key="item.id">
                                        <div class="customer_data">
                                            <label>{{ item?.name }}</label>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="col-md-12 row" v-if="this.usermobiles?.length > 0">
                                    <div class="col-md-3" v-for="(item, index) in usermobiles" :key="item.id">
                                        <div class="customer_data">
                                            <input type="checkbox" :id="item.id" :value="item.id"
                                                v-model="selecteduserlead[index]" @click="selectedlead($event)">
                                            <label :for="item.id" class="ml5px" style="font-size: 15px;">{{
                                                item?.customername }}</label>
                                            <!-- <span> ({{ item.mobile }})</span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center">
                <button class="btn btn-primary" @click="sendTemplate()">Send</button>
            </div>
        </div>
    </div>
</template>
    
<script>
import { mapGetters } from 'vuex';
import SingleWhatsAppTemplate from './SingleWhatsAppTemplate.vue';
// import WhatsAppUser from './WhatsAppUser.vue';
import SelectedWhatsAppTemplate from '../whatsapp/SelectedWhatsAppTemplate.vue'
import axios from 'axios';
export default {
    components: { SingleWhatsAppTemplate, SelectedWhatsAppTemplate },
    data() {
        return {
            userid: '',
            mobile: '',
            selecteduser: [],
            selecteduserlead: [],
            selectAll: true,
            userlist: [],
            userleadlist: [],
            totaluser: 0,
            totaluserlead: 0,
            mobilenos: []
        };
    },
    computed: {
        ...mapGetters(['loggedinuser', 'list', 'edititem', 'userids', 'sampleimage', 'usermobiles'])
    },
    mounted() {
        this.getTopPosition()
        this.refresh();
        
    },
    methods: {
        refresh() {
            //get the wattsapp templates
            window.axios.post("api/threads/wattsapp/company/templates/approved/fetch")
                .then(response => this.processResponse(response.data))
                .catch(error => console.log(error));
        },

        processResponse(data) {
            this.$store.commit('assignlist', data.list);
        },
        getTopPosition() {
            window.scrollTo({ top: 0, behaviour: "smooth" });
        },
        sendTemplate() {
            
            if ((this.userlist == "" || this.userlist == null) && (this.userleadlist == "" || this.userleadlist == null)) {
                alert("Please select customer.")
            } else {
                if (this.userlist?.length > 1001 || this.userleadlist?.length > 1001) {
                    alert('Message cannot send more than 1000 customer.')
                } else {
                    this.getUserMobile()
                    this.$store.commit('assignloadingstatus', 1)
                    let imageurl = ''
                    if (this.sampleimage?.length == 0) {
                        imageurl = this.edititem.components[this.checkHeader()]['sampleurl']
                    } else {
                        imageurl = this.sampleimage?.fullurl
                    }

                    let sendtotype = 0
                    if (this.userlist?.length > 0) {
                        sendtotype = 1
                    } else if (this.userleadlist?.length > 0) {
                        sendtotype = 2
                    }

                    let param = {
                        templateid: this.edititem.id, templatename: this.edititem.wattsapptemplatename, userids: this.userlist.join(),
                        language: this.edititem.language, sendtotype: sendtotype, imageurl: imageurl, mobilenos: this.mobilenos.join()
                    }
                    console.log(param)
                    axios.post('api/threads/wattsapp/bulk', param)
                        .then((response) => this.processSendResponse(response.data))
                        .catch((error) => console.log(error))
                }
            }
        },
        getUserMobile() {
            if (this.usermobiles?.length > 0 && this.userleadlist?.length > 0) {
                this.usermobiles?.forEach(element => {
                    this.userleadlist.forEach(value => {
                        if (element.id == value) {
                            this.mobilenos.push(element.mobile)
                        }
                    })
                });
            }
        },
        initialize() {
            console.log("initialize")
            this.userid = '',
            this.mobile = '',
            this.selecteduser = [],
            this.selecteduserlead = [],
            this.selectAll = true,
            this.userlist = [],
            this.userleadlist = [],
            this.totaluser = 0,
            this.totaluserlead = 0,
            this.mobilenos = []
        },
        processSendResponse(data) {
            console.log(data)
            if (data.status == 0) {
                // alert('Message send successfully..')
                this.$store.commit('assignloadingstatus', 0)
                this.$store.commit('assignedititem', null)
                this.$store.commit('assignsampleimage', null)
                this.$store.commit('assignusermobiles', null)
                this.$store.commit('assignuserids', null)
                // this.$store.commit('assignviewno', 1)
                window.location.reload()
            }
            this.initialize()
        },
        checkHeader() {
            let value = 0
            this.edititem.components.find((element) => {
                if (element.componenttypeint == 1) {
                    value = element.componenttypeint
                }
            })
            return value
        },
        selected(event) {
            let userid = event.target.value
            this.$store.commit('assignloadingstatus', 1)
            if (this.userlist.includes(parseInt(userid))) {
                for (let i = 0; i < this.userlist.length; i++) {
                    if (this.userlist[i] == userid) {
                        this.userlist.splice(i, 1);
                    }
                }
                console.log("users ", this.userlist)
            } else {
                this.userlist.push(parseInt(userid))
            }
            this.totaluser = this.userlist?.length
            setTimeout(() => {
                this.$store.commit('assignloadingstatus', 0)
            }, 500);
        },
        selectedlead(event) {
            let userid = event.target.value
            this.$store.commit('assignloadingstatus', 1)
            if (this.userleadlist.includes(parseInt(userid))) {
                for (let i = 0; i < this.userleadlist.length; i++) {
                    if (this.userleadlist[i] == userid) {
                        this.userleadlist.splice(i, 1);
                    }
                }
            }
            else {
                this.userleadlist.push(parseInt(userid))
            }
            this.totaluserlead = this.userleadlist?.length

            console.log("userlead length", this.totaluserlead)
            console.log("users 2", this.userleadlist)
            setTimeout(() => {
                this.$store.commit('assignloadingstatus', 0)
            }, 500);
        },
        toggleUserIdsCheckboxes() {
            this.$store.commit('assignloadingstatus', 1)
            // console.log("User-ids", this.userids)
            this.userlist = []
            this.selecteduser = this.selectAll ? [...this.userids] : [];

            if (this.selectAll == true) {
                this.selecteduser.forEach(element => {
                    this.userlist.push(element.id)
                })
                this.userlist = this.userlist.join().split(',').map(Number)
            } else {
                this.userlist = []
            }
            this.totaluser = this.userlist?.length ?? 0
            setTimeout(() => {
                this.$store.commit('assignloadingstatus', 0)
            }, 1000);
        },
        toggleUserMobilesCheckboxes() {
            this.$store.commit('assignloadingstatus', 1)
            // console.log("User mobiles here", this.usermobiles)
            this.userleadlist = []
            this.selecteduserlead = this.selectAll ? [...this.usermobiles] : [];

            if (this.selectAll == true) {
                this.selecteduserlead.forEach(element => {
                    this.userleadlist.push(element.id)
                })
                this.userleadlist = this.userleadlist.join().split(',').map(Number)
            } else {
                this.userleadlist = []
            }
            this.totaluserlead = this.userleadlist?.length ?? 0
            setTimeout(() => {
                this.$store.commit('assignloadingstatus', 0)
            }, 1000);
        }
    },
}
</script>
<style scoped>
.grd-bg-info {
    background-color: #80d3f9;
}

.select_all {
    padding: 5px 10px;
    background: #475c78;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.grd-orange {
    background: #FF9933;
}

.bg-green,
.bg-green>.small-box-footer {
    background-color: #138808;
    color: black;
}

.grd-white {
    background: white;
    color: black;
}

.grd-white-color {
    color: black;
}


.inner {}
</style>
    