<template>
    <div>
        <div class="col-md-12">
            <div>

                <div class="row">
                    <div class="col-md-12 text-center">
                        <h5 class="control-label mt-20 mb-20"><span class="selected-temp">Selected Template</span></h5>
                    </div>
                    <!-- <div class="col-md-6 text-end">
                            <label class="control-label">Mobile no.</label>
                            <input type="text" class="customer_data" v-model="mobileno" style="margin-left: 10px;">
                        </div> -->
                </div>

                <div class="row flex-center-row">
                    <div class="col-md-4">
                        <div class="temp_size">
                            <!-- v-if="checkHeader() == 1" -->
                            <div   v-if="checkHeader() == 1" >
                                <div>
                                    <h5 class="mt-10"><span class="ml-10">Image</span></h5>
                                </div>
                                <div class="col-md-12">
                                    <div class="temp_header text-center">
                                        <img :src="this.edititem.components[checkHeader()]['sampleurl']"
                                            style="width: 180px;height: 180px;">
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12">
                                <div>
                                    <h5 class="mt-10"><span class="ml-20">Content</span></h5>
                                </div>
                                <div class="">
                                    <!-- :class="checkHeader() == 1 ? 'temp_body' : 'new_temp_body'" -->
                                    <div    :class="checkHeader() == 1 ? 'temp_body' : 'new_temp_body'">
                                        <!-- Body -->
                                        <div class="col-md-12 mb-10 mt-10">
                                            <span style="word-wrap: break-word;">{{ getjsondata() }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- v-if="checkHeader() == 1" -->
                    <div class="col-md-4"   v-if="checkHeader() == 1"  >
                        <div class="temp_size">
                            <div>
                                <div>
                                    <h5 class="mt-10"><span class="ml-10">Select Image</span></h5>
                                </div>
                                <div class="col-md-12">
                                    <div class="temp_header">
                                        <div class=" row">
                                            <div class="col-md-6">
                                                <img :src="sampleimage?.fullurl" style="height: 140px;width: 150px;">
                                            </div>
                                            <div class="col-md-6 text-end">
                                                <input type="file" id="attach_file" name="files" style="width: 85%;"
                                                    @change="processFile2($event)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div>
                                    <h5 class="mt-10"><span class="ml-20">Content</span></h5>
                                </div>
                                <div class="">
                                    <!-- :class="checkHeader() == 1 ? 'temp_body' : 'new_temp_body'" -->
                                    <div   :class="checkHeader() == 1 ? 'temp_body' : 'new_temp_body'" >
                                        <!-- Body -->
                                        <div class="col-md-12 mb-10 mt-10">
                                            <span style="word-wrap: break-word;">{{ getjsondata() }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
// import axios from 'axios';
import UploadMixin2 from '../../components/utilities/UploadMixin2.vue'
// import WhatsAppUser from '../../pages/whatsapp/WhatsAppUser.vue'
export default {
    mixins: [UploadMixin2],
    components: {  },
    data() {
        return {
         sample:[]
            
        }
    },
    mounted() {
     
    },
    computed: {
        ...mapGetters(['customers', 'edititem', 'viewno','sampleimage'])
    },
    methods: {
        uploaded() {
            // this.sample = this.uploadedimage
            this.$store.commit('assignsampleimage',this.uploadedimage)
            this.$store.commit('assignloadingstatus',0)
            console.log('Sample image here ',this.sampleimage)
        },
        getjsondata() {
            let value = null
            this.edititem.components.find(function (element) {
                if (element.componenttypeint == 2) {
                    value = element.bodytext
                }
            })
            return value
        },
        checkHeader() {
            let value = 0
            this.edititem.components.find((element) => {
                if (element.componenttypeint == 1) {
                    value = element.componenttypeint
                }
            })
            return value
        },
    }
}
</script>